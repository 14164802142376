import { Flex } from '@mantine/core';
import { ReactNode } from 'react';
import { MainSection } from './main-section';
import { OverviewSection } from './overview-section';
import { SideFilters } from './side-filters';
import { SideSection } from './side-section';

type Props = {
  controlBar: ReactNode;
  overview: {
    title?: string;
    content: ReactNode;
  };
  sideFilters: ReactNode;
  mainSection: ReactNode;
  sideSection?: ReactNode;
  showSideSection?: boolean;
};

export const PageLayout = ({
  controlBar,
  overview,
  sideFilters,
  mainSection,
  sideSection,
  showSideSection = false,
}: Props) => {
  return (
    <Flex direction="column" gap={16} miw={1000} h="calc(100vh - 110px)">
      {controlBar}
      <OverviewSection title={overview.title} content={overview.content} />
      <Flex direction="row" gap={16} style={{ flex: 1, minHeight: 0 }}>
        <SideFilters content={sideFilters} />
        <MainSection content={mainSection} />
        {showSideSection && sideSection && <SideSection content={sideSection} />}
      </Flex>
    </Flex>
  );
};
