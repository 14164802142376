import { styled } from '@linaria/react';
import { Flex, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { Integration } from '../../../api/integrations-client/integrations-client.type';
import { capitalizeFirstLetter } from '../../../helpers/string-helpers/string-helpers';
import { useIntegrationsStore } from '../../../store/integrations-store/integrations-store';
import { setIntegration } from '../../../store/integrations-store/integrations-store.actions';
import { baseWhite, inkBase, inkLight } from '../../../styles/design-tokens';
import { Button } from '../../../ui-library/button/button';
import { Divider } from '../../../ui-library/divider/divider';
import { Icon } from '../../../ui-library/icon/icon';
import { Text } from '../../../ui-library/typography/typography';
import { formatLogoSrc } from './add-new-integration.helpers';
import { StyledModal } from './add-new-integration.styled';
import { BaseUrlForm as BaseUrlFormType } from './add-new-integration.types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onStartIntegration: (integration: Integration, baseUrl: string) => void;
}

export const AddNewIntegrationModal = ({ isOpen, onClose, onStartIntegration }: Props) => {
  const integration = useIntegrationsStore((state) => state.integration);

  const handleClose = () => {
    setIntegration(null);
    form.reset();
    onClose();
  };

  const form = useForm<BaseUrlFormType>({
    initialValues: {
      baseUrl: '',
    },
    validate: {
      baseUrl: (value) => {
        try {
          new URL(value);
          return null;
        } catch {
          return 'Invalid URL format';
        }
      },
    },
  });

  if (!integration) {
    return null;
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    form.validate();

    if (form.isValid()) {
      onStartIntegration(integration, form.values.baseUrl);
    }
  };

  return (
    <StyledModal
      opened={isOpen}
      onClose={handleClose}
      size="lg"
      padding={40}
      centered
      title={
        <Flex align="center" gap={16}>
          <IntegrationIcon src={formatLogoSrc(integration.logo)} alt={integration.name} />
          <Text size="large" lineHeight="none" weight="bold">
            {integration.name}
          </Text>
        </Flex>
      }
    >
      <form onSubmit={handleSubmit}>
        <Flex direction="column" gap={32}>
          <Flex direction="column" gap={24}>
            <Divider />
            <Text size="small" color={inkBase}>
              {integration.description}
            </Text>
            <Divider />
            <TextInput
              required
              mb="md"
              label={`Enter the base url of the ${capitalizeFirstLetter(
                integration.name,
              )} instance you are trying to integrate with`}
              placeholder="For example, https://foo.atlassian.net"
              {...form.getInputProps('baseUrl')}
            />
            <Divider />
          </Flex>

          <Flex justify="flex-end" gap={24}>
            <Button
              variant="outline"
              radius="xl"
              leftSection={<Icon name="cancel" size={18} color={inkLight} />}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button type="submit" radius="xl" leftSection={<Icon name="restart_alt" size={18} color={baseWhite} />}>
              Start Integration
            </Button>
          </Flex>
        </Flex>
      </form>
    </StyledModal>
  );
};

const IntegrationIcon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 6px;
  object-fit: contain;
`;
