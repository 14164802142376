import { styled } from '@linaria/react';
import { MultiSelect } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { PortfolioStatusesByProjectsAndBoards } from '../../../../../api/portfolio-client/portfolio-client.type';
import { Workflow } from '../../../../../api/workflows-client/workflows-client.type';
import { redBase, skyDark, skyLightest } from '../../../../../styles/design-tokens';
import { Icon } from '../../../../../ui-library/icon/icon';
import { Text } from '../../../../../ui-library/typography/typography';
import { Table, TableColumn } from '../../table';
import {
  getMappedAndUnmappedStatusesForSubproject,
  getStatusesForSelectedBoards,
  maybeRemoveMappedStatusesFromWorkflowSteps,
} from '../workflows.helpers';
import { WorkflowAction } from './reducer';

type TableRow = {
  id: string;
  name: string;
  projectName: string;
  statusMapping: string | null;
  isProject: boolean;
  parentId?: string;
};

function EditBoards({
  state,
  dispatch,
  statusesByProjectsAndBoards,
}: {
  state: Workflow;
  dispatch: (action: WorkflowAction) => void;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
}) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
      <Text weight="bold">Boards</Text>
      {statusesByProjectsAndBoards && (
        <>
          <MultiSelect
            placeholder={
              (state.steps || []).flatMap((step) => step.mappings).length ? undefined : 'Search and Add Boards'
            }
            data={Object.keys(statusesByProjectsAndBoards).map((projectId) => ({
              key: projectId,
              group: statusesByProjectsAndBoards[projectId].name,
              items: Object.entries(statusesByProjectsAndBoards[projectId].subprojects).map(
                ([subprojectId, subproject]) => ({
                  key: subprojectId,
                  value: subprojectId,
                  label: subproject.name,
                }),
              ),
            }))}
            value={state.subprojects}
            onChange={(values) => {
              // Step 1: Get all statuses for the selected boards
              const allStatusesForSelectedBoards = getStatusesForSelectedBoards(statusesByProjectsAndBoards, values);
              // Step 2: Remove any statuses from state that have been deselected
              const newSteps = maybeRemoveMappedStatusesFromWorkflowSteps(state, allStatusesForSelectedBoards);
              // Step 4: Update the selected boards
              dispatch({
                type: 'SET_WORKFLOW',
                payload: { ...state, steps: newSteps, subprojects: values },
              });
            }}
            leftSection={<Icon name="search" size={16} color={skyDark} />}
            style={{ width: '100%' }}
            radius="xl"
            size="xs"
          />
          {Object.entries(statusesByProjectsAndBoards).map(([projectId, projectData]) => {
            const selectedSubprojectsFromProjectData = Object.entries(projectData.subprojects).filter(
              ([subprojectId, _]) => (state.subprojects || []).includes(subprojectId),
            );
            if (!selectedSubprojectsFromProjectData.length) return null;

            return (
              <span key={projectId}>
                <Text weight="bold" size="small">
                  {projectData.name}
                </Text>
                {selectedSubprojectsFromProjectData
                  .filter(([subprojectId, _]) => state.subprojects.includes(subprojectId))
                  .map(([subprojectId, { name }]) => {
                    return (
                      <BoardItem
                        key={subprojectId}
                        name={name}
                        onRemove={() => {
                          // Step 1: Filter out the deleted board from the selected boards
                          const remainingBoards = state.subprojects.filter((board) => board !== subprojectId);
                          // Step 2: Get all statuses for the remaining boards, if any
                          const allStatusesForSelectedBoards = getStatusesForSelectedBoards(
                            statusesByProjectsAndBoards,
                            remainingBoards,
                          );
                          // Step 3: Remove extra statuses from the workflow steps
                          const newSteps = maybeRemoveMappedStatusesFromWorkflowSteps(
                            state,
                            allStatusesForSelectedBoards,
                          );
                          // Step 4: Update the selected boards
                          dispatch({
                            type: 'SET_WORKFLOW',
                            payload: { ...state, steps: newSteps, subprojects: remainingBoards },
                          });
                        }}
                      />
                    );
                  })}
              </span>
            );
          })}
        </>
      )}
    </div>
  );
}

function formatDataForTable(
  selectedBoards: string[],
  mappedStatusBuckets: { [key: string]: string[] },
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards,
): TableRow[] {
  let tableData: TableRow[] = [];

  const filteredStatusesByProjectsAndBoards = Object.entries(statusesByProjectsAndBoards).filter(([_, projectData]) =>
    selectedBoards.some((board) => projectData.subprojects[board]),
  );

  filteredStatusesByProjectsAndBoards.forEach(([projectId, projectData]) => {
    tableData.push({
      id: projectId,
      name: projectData.name,
      projectName: projectData.name,
      statusMapping: null,
      isProject: true,
      parentId: undefined,
    });
    Object.entries(projectData.subprojects)
      .filter(([subprojectId, _]) => selectedBoards.includes(subprojectId))
      .forEach(([subprojectId, subprojectData]) => {
        const { mappedStatuses, unmappedStatuses } = getMappedAndUnmappedStatusesForSubproject(
          subprojectData.statuses,
          mappedStatusBuckets,
        );

        tableData.push({
          id: subprojectId,
          name: subprojectData.name,
          projectName: projectData.name,
          statusMapping: `${mappedStatuses.length} / ${unmappedStatuses.length}`,
          isProject: false,
          parentId: projectId,
        });
      });
  });
  return tableData;
}

function Boards({
  state,
  statusesByProjectsAndBoards,
}: {
  state: Workflow;
  statusesByProjectsAndBoards: PortfolioStatusesByProjectsAndBoards | undefined;
}) {
  const columns: TableColumn<TableRow>[] = [
    {
      key: 'name',
      label: 'Project / Board Name',
      width: '40%',
      sortable: true,
      render: (_value: TableRow[keyof TableRow], row: TableRow) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginLeft: row.isProject ? 0 : 12 }}>
          <Text weight={row.isProject ? 'bold' : 'medium'}>{row.name}</Text>
        </div>
      ),
    },
    {
      key: 'statusMapping',
      label: 'Active / Inactive Statuses',
      width: '30%',
      sortable: true,
      render: (_value: TableRow[keyof TableRow], row: TableRow) => row.statusMapping,
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '500px',
        overflow: 'hidden',
      }}
    >
      <Text size="large" weight="bold" style={{ padding: '4px 0' }}>
        Assigned Boards
      </Text>
      {statusesByProjectsAndBoards && (state.subprojects || []).length ? (
        <TableContainer>
          <Table
            data={formatDataForTable(
              state.subprojects,
              state.steps.reduce(
                (acc, step) => {
                  acc[step.name] = step.mappings.map((mapping) => mapping.external_name);
                  return acc;
                },
                {} as { [stepName: string]: string[] },
              ),
              statusesByProjectsAndBoards,
            )}
            columns={columns}
            selectedRowId={undefined}
            onRowClick={() => {}}
            getRowId={(row) => row.id}
            stickyHeader
            isChildRow={(row) => !row.isProject}
            getParentId={(row) => row.parentId || ''}
            highlightParentRows
          />
        </TableContainer>
      ) : (
        <EmptyStateContainer>
          <Icon name="check_circle_outlined" size={40} color={skyDark} />
          <Text size="large" color={skyDark}>
            No boards selected
          </Text>
        </EmptyStateContainer>
      )}
    </div>
  );
}

function BoardItem({ name, onRemove }: { name: string; onRemove: () => void }) {
  const { hovered, ref } = useHover();
  return (
    <ListItem ref={ref}>
      <Text size="small">{name}</Text>
      <Icon
        name="do_not_disturb_on"
        size={16}
        color={hovered ? redBase : skyDark}
        style={{ cursor: 'pointer' }}
        onClick={onRemove}
      />
    </ListItem>
  );
}

const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 4px;
  border-radius: 4px;
  &:hover {
    background: ${skyLightest};
  }
`;

const TableContainer = styled.div`
  flex: 1;
  overflow: auto;
`;

export { Boards, EditBoards };
