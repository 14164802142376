import { Group } from '@mantine/core';
import { useDocumentTitle } from '../../helpers/general-helpers';
import { secondaryBase } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { H2 } from '../../ui-library/typography/typography';
import { MenuDrivenContainer } from '../menu-driven-container/menu-driven-container';
import { RiskAnalysis } from './risk-analysis/risk-analysis';

export function Adherence() {
  useDocumentTitle('Adherence - Bloomfilter');

  const header = (
    <Group>
      <Icon name="emergency" color={secondaryBase} size={36} />

      <H2>Risk Analysis</H2>
    </Group>
  );

  return (
    // We changed the name to Risk Analysis as it is the only tab for now, in the future change it to Adherence
    <MenuDrivenContainer header={header}>
      <RiskAnalysis />
    </MenuDrivenContainer>
  );
}
