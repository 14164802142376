import { Group, Stack, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { EmptyFilterResult } from '../../../../components/filters/empty-filter-result';
import { baseWhite, inkLight } from '../../../../styles/design-tokens';
import { Button } from '../../../../ui-library/button/button';
import { Icon } from '../../../../ui-library/icon/icon';
import { Table } from '../../../data-management/components/table';
import { RiskAnalysisTask } from '../risk-analysis.client.type';
import { sortRiskAnalysisData } from '../risk-analysis.helpers';
import { getTaskTableColumns, handleTaskTableDownloadCSV } from './risk-analysis-task-table.hooks';

const ITEMS_PER_PAGE = 20;

export function RiskAnalysisTaskTable({
  tasks,
  onTaskClick,
  isEpic = false,
}: {
  tasks: RiskAnalysisTask[];
  onTaskClick: (task: RiskAnalysisTask) => void;
  isEpic?: boolean;
}) {
  const [selectedPage, setSelectedPage] = useState(1);
  const [filteredTasks, setFilteredTasks] = useState(tasks);
  const [searchTerm, setSearchTerm] = useState('');

  // Reset search and filtered tasks when tasks or isEpic changes
  useEffect(() => {
    setSearchTerm('');
    setFilteredTasks(tasks);
    setSelectedPage(1);
  }, [tasks, isEpic]);

  const sortData = (
    data: RiskAnalysisTask[],
    sortBy: keyof RiskAnalysisTask,
    direction: 'asc' | 'desc',
  ): RiskAnalysisTask[] => {
    return sortRiskAnalysisData(data, sortBy, direction);
  };

  return (
    <Stack>
      <Group justify="space-between">
        <Button
          variant="secondary"
          radius="xl"
          size="sm"
          leftSection={<Icon name="format_list_bulleted" color={baseWhite} variant="filled" size={16} />}
        >
          Table View
        </Button>
        <Group>
          <TextInput
            placeholder={`Search ${isEpic ? 'epics' : 'tasks'}...`}
            radius="xl"
            size="sm"
            value={searchTerm}
            leftSection={<Icon name="search" variant="filled" size={16} color={inkLight} />}
            style={{ width: 300 }}
            onChange={(event) => {
              const newSearchTerm = event.currentTarget.value;
              setSearchTerm(newSearchTerm);
              const filtered = tasks.filter(
                (task) =>
                  task.name?.toLowerCase().includes(newSearchTerm.toLowerCase()) ||
                  task.description?.toLowerCase().includes(newSearchTerm.toLowerCase()) ||
                  task.code.toLowerCase().includes(newSearchTerm.toLowerCase()),
              );
              setFilteredTasks(filtered);
              setSelectedPage(1);
            }}
          />
          <Button
            variant="secondary"
            radius="xl"
            size="sm"
            leftSection={<Icon name="download" color={baseWhite} variant="filled" size={16} />}
            onClick={() => handleTaskTableDownloadCSV(isEpic, filteredTasks)}
          >
            Export
          </Button>
        </Group>
      </Group>
      {filteredTasks.length > 0 ? (
        <Table<RiskAnalysisTask>
          data={filteredTasks}
          columns={getTaskTableColumns(isEpic)}
          getRowId={(task: RiskAnalysisTask) => task.id}
          onRowClick={(task: RiskAnalysisTask) => {
            onTaskClick(task);
          }}
          stickyHeader
          pageSize={ITEMS_PER_PAGE}
          currentPage={selectedPage}
          totalItems={filteredTasks.length}
          onPageChange={(page: number) => {
            setSelectedPage(page);
          }}
          customSort={sortData}
        />
      ) : (
        <EmptyFilterResult objectName={isEpic ? 'epics' : 'tasks'} />
      )}
    </Stack>
  );
}
