import { styled } from '@linaria/react';
import { Stack } from '@mantine/core';
import { Fragment, useEffect, useRef, useState } from 'react';
import { FilterPanel } from '../../../components/filters/filter-panel';
import { BrandedLoadingOverlay } from '../../../components/loader/branded-loader';
import { PageLayout } from '../../../components/page-layout/page-layout';
import { RiskAnalysisInitiativeSideSection } from '../../../components/side-section/risk-analysis-initiative-side-section';
import { RiskAnalysisPullRequestSideSection } from '../../../components/side-section/risk-analysis-pull-request-side-section';
import { RiskAnalysisTaskSideSection } from '../../../components/side-section/risk-analysis-task-side-section';
import { useAdherenceStore } from '../../../store/adherence-store/adherence-store';
import { useGlobalStore } from '../../../store/global-store/global-store';
import { getFilterGroups, useFilteredData } from './filters/risk-analysis-filter-groups.helpers';
import { useRiskAnalysisFilters } from './filters/risk-analysis-filters.hooks';
import { RiskAnalysisInitiativeOverview } from './initiatives/risk-analysis-initiative-overview';
import { RiskAnalysisInitiativeTable } from './initiatives/risk-analysis-initiative-table';
import { RiskAnalysisPullRequestOverview } from './pull-requests/risk-analysis-pull-request-overview';
import { RiskAnalysisPullRequestTable } from './pull-requests/risk-analysis-pull-request-table';
import {
  RiskAnalysisInitiative,
  RiskAnalysisPullRequest,
  RiskAnalysisSection,
  RiskAnalysisTask,
} from './risk-analysis.client.type';
import {
  useRiskAnalysisCreators,
  useRiskAnalysisEpics,
  useRiskAnalysisEpicsFilterOptions,
  useRiskAnalysisInitiatives,
  useRiskAnalysisInitiativesOptions,
  useRiskAnalysisProjectOptions,
  useRiskAnalysisPullRequests,
  useRiskAnalysisRepositoryOptions,
  useRiskAnalysisRiskCategories,
  useRiskAnalysisTaskTypes,
  useRiskAnalysisTasks,
} from './risk-analysis.hooks';
import { RiskAnalysisTaskOverview } from './tasks/risk-analysis-task-overview';
import { RiskAnalysisTaskTable } from './tasks/risk-analysis-task-table';

export function RiskAnalysis() {
  const useAI = useGlobalStore((state) => state.useAI);
  const portfolio = useGlobalStore((state) => state.portfolio);
  const selectedSection = useAdherenceStore((state) => state.selectedRiskAnalysisSection);
  const setSelectedSection = useAdherenceStore((state) => state.setSelectedRiskAnalysisSection);
  const [selectedTask, setSelectedTask] = useState<RiskAnalysisTask | null>(null);
  const [selectedInitiative, setSelectedInitiative] = useState<RiskAnalysisInitiative | null>(null);
  const [selectedPullRequest, setSelectedPullRequest] = useState<RiskAnalysisPullRequest | null>(null);

  const { data: tasks, isFetching: tasksIsFetching } = useRiskAnalysisTasks(portfolio?.id ?? '');
  const { data: initiatives, isFetching: initiativesIsFetching } = useRiskAnalysisInitiatives(portfolio?.id ?? '');
  const { data: epics, isFetching: epicsIsFetching } = useRiskAnalysisEpics(portfolio?.id ?? '');
  const { data: pullRequests, isFetching: pullRequestsIsFetching } = useRiskAnalysisPullRequests(portfolio?.id ?? '');

  const taskTypes = useRiskAnalysisTaskTypes(tasks ?? []);
  const epicsFilterOptions = useRiskAnalysisEpicsFilterOptions(tasks ?? []);
  const initiativesFilterOptions = useRiskAnalysisInitiativesOptions(tasks ?? []);
  const creators = useRiskAnalysisCreators(tasks ?? []);
  const projectOptions = useRiskAnalysisProjectOptions(tasks ?? []);
  const riskCategories = useRiskAnalysisRiskCategories(tasks ?? []);
  const initiativeFilterOptionsForEpic = useRiskAnalysisInitiativesOptions(epics ?? []);
  const repositoryOptions = useRiskAnalysisRepositoryOptions(pullRequests ?? []);

  const filters = useRiskAnalysisFilters(creators, projectOptions, taskTypes, riskCategories, repositoryOptions);
  const initialLoadRef = useRef(false);
  const shouldResetRef = useRef(false);

  // Set initial filter values when data is loaded
  useEffect(() => {
    if (tasks && tasks.length > 0 && !initialLoadRef.current) {
      shouldResetRef.current = true;
      filters.handleResetFilters(selectedSection);
      initialLoadRef.current = true;
      shouldResetRef.current = false;
    }
  }, [tasks, creators, projectOptions, taskTypes, riskCategories, selectedSection, filters]);

  // Reset filters when section changes
  useEffect(() => {
    if (initialLoadRef.current && shouldResetRef.current) {
      filters.handleResetFilters(selectedSection);
      shouldResetRef.current = false;
    }
  }, [selectedSection, filters]);

  // Handle section changes
  useEffect(() => {
    shouldResetRef.current = true;
  }, [selectedSection]);

  // Remove the separate initiatives effect since we're not needed anymore
  useEffect(() => {
    if (epics && epics.length > 0 && !initialLoadRef.current) {
      shouldResetRef.current = true;
      filters.handleResetFilters(selectedSection);
      initialLoadRef.current = true;
      shouldResetRef.current = false;
    }
  }, [epics, projectOptions, riskCategories, selectedSection, filters]);

  const { filteredTasks, filteredInitiatives, filteredEpics, filteredPullRequests } = useFilteredData(
    tasks,
    initiatives,
    epics,
    pullRequests,
    filters,
  );

  const handleSideSectionClose = () => {
    setSelectedTask(null);
    setSelectedInitiative(null);
    setSelectedPullRequest(null);
  };

  const filterGroups = getFilterGroups(selectedSection, {
    projectOptions,
    taskTypes,
    riskCategories,
    creators,
    initiativesFilterOptions,
    epicsFilterOptions,
    initiativeFilterOptionsForEpic,
    repositoryOptions,
    filters,
  });

  return (
    <Fragment>
      <BrandedLoadingOverlay
        visible={tasksIsFetching && initiativesIsFetching && epicsIsFetching && pullRequestsIsFetching}
        variant="colored"
      />
      {useAI && (
        <PageLayout
          controlBar={null}
          overview={{
            title: 'Risk Overview',
            content: (() => {
              switch (selectedSection) {
                case RiskAnalysisSection.TASKS:
                  return <RiskAnalysisTaskOverview tasks={filteredTasks} />;
                case RiskAnalysisSection.INITIATIVES:
                  return <RiskAnalysisInitiativeOverview initiatives={filteredInitiatives} />;
                case RiskAnalysisSection.EPICS:
                  return <RiskAnalysisTaskOverview tasks={filteredEpics} isEpic={true} />;
                case RiskAnalysisSection.PULL_REQUESTS:
                  return <RiskAnalysisPullRequestOverview pullRequests={filteredPullRequests} />;
                default:
                  return null;
              }
            })(),
          }}
          sideFilters={
            <Stack gap="md">
              <FilterPanel
                selectedOption={selectedSection}
                options={Object.values(RiskAnalysisSection).map((section) => ({
                  value: section,
                  label: section,
                }))}
                onOptionChange={(value) => {
                  setSelectedSection(value as RiskAnalysisSection);
                  filters.handleResetFilters(value as RiskAnalysisSection);
                  handleSideSectionClose();
                }}
                filterGroups={filterGroups}
                onResetFilters={() => filters.handleResetFilters(selectedSection)}
              />
            </Stack>
          }
          mainSection={(() => {
            switch (selectedSection) {
              case RiskAnalysisSection.TASKS:
                return <RiskAnalysisTaskTable tasks={filteredTasks} onTaskClick={setSelectedTask} />;
              case RiskAnalysisSection.INITIATIVES:
                return (
                  <RiskAnalysisInitiativeTable
                    initiatives={filteredInitiatives}
                    onInitiativeClick={setSelectedInitiative}
                  />
                );
              case RiskAnalysisSection.EPICS:
                return <RiskAnalysisTaskTable tasks={filteredEpics} onTaskClick={setSelectedTask} isEpic={true} />;
              case RiskAnalysisSection.PULL_REQUESTS:
                return (
                  <RiskAnalysisPullRequestTable
                    pullRequests={filteredPullRequests}
                    onPullRequestClick={setSelectedPullRequest}
                  />
                );
              default:
                return null;
            }
          })()}
          sideSection={(() => {
            switch (selectedSection) {
              case RiskAnalysisSection.TASKS:
                return <RiskAnalysisTaskSideSection task={selectedTask} onClose={handleSideSectionClose} />;
              case RiskAnalysisSection.INITIATIVES:
                return (
                  <RiskAnalysisInitiativeSideSection initiative={selectedInitiative} onClose={handleSideSectionClose} />
                );
              case RiskAnalysisSection.EPICS:
                return <RiskAnalysisTaskSideSection task={selectedTask} onClose={handleSideSectionClose} />;
              case RiskAnalysisSection.PULL_REQUESTS:
                return (
                  <RiskAnalysisPullRequestSideSection
                    pullRequest={selectedPullRequest}
                    onClose={handleSideSectionClose}
                  />
                );
              default:
                return null;
            }
          })()}
          showSideSection={selectedTask !== null || selectedInitiative !== null || selectedPullRequest !== null}
        />
      )}
    </Fragment>
  );
}

export const RiskBadge = styled.span<{ color: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${(props) => `${props.color}15`};
  color: ${(props) => props.color};
  border: 1px solid ${(props) => `${props.color}30`};
`;
