import { styled } from '@linaria/react';
import { Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ReactNode } from 'react';
import { secondaryBase } from '../../styles/design-tokens';
import { Icon } from '../../ui-library/icon/icon';
import { Text } from '../../ui-library/typography/typography';
import { CommonContainer } from './page-layout.styles';

type Props = {
  content: ReactNode;
};

export const SideFilters = ({ content }: Props) => {
  const [opened, { toggle }] = useDisclosure(true);

  return (
    <Container>
      <ExpandButton onClick={toggle}>
        <Flex align="center" gap={12} style={{ transform: 'rotate(270deg)' }}>
          <Icon name="filter_list" size={18} color={secondaryBase} />
          <Text size="small" weight="bold" color={secondaryBase}>
            Filters
          </Text>
        </Flex>
      </ExpandButton>

      {opened && <FiltersContainer>{content}</FiltersContainer>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: 100%;
  min-height: 0;
  overflow: hidden;
`;

const ExpandButton = styled.div`
  width: 42px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--base-white);
  cursor: pointer;
  height: 100%;
  border-radius: 8px;
  border: 1px solid transparent;
  transition: border-color 0.2s ease;
  &:hover {
    border-color: var(--secondary-base);
  }
`;

const FiltersContainer = styled(CommonContainer)`
  width: 286px;
`;
