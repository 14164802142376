import { styled } from '@linaria/react';
import { Group, Checkbox as MantineCheckbox, Stack } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { inkLight } from '../../styles/design-tokens';
import { Button } from '../../ui-library/button/button';
import { Divider } from '../../ui-library/divider/divider';
import { Icon } from '../../ui-library/icon/icon';
import { Select } from '../../ui-library/select/select';
import { Text } from '../../ui-library/typography/typography';
import { FilterGroup } from './filter-panel.types';

type Props<T extends string> = {
  selectedOption: T;
  options: { value: T; label: string }[];
  onOptionChange: (value: T) => void;
  filterGroups: FilterGroup[];
  onResetFilters: () => void;
};

export function FilterPanel<T extends string>({
  selectedOption,
  options,
  onOptionChange,
  filterGroups,
  onResetFilters,
}: Props<T>) {
  const collapsedGroupsRef = useRef<Record<string, boolean>>({});
  const [, forceUpdate] = useState({});
  const [allSelected, setAllSelected] = useState<Record<string, boolean>>({});
  const initializedRef = useRef<Record<string, boolean>>({});
  const initializationDoneRef = useRef(false);

  // Initialize collapsed state for new groups
  useEffect(() => {
    filterGroups.forEach((group) => {
      if (!(group.name in collapsedGroupsRef.current)) {
        collapsedGroupsRef.current[group.name] = true;
      }
    });
  }, [filterGroups]);

  const toggleGroup = (groupName: string) => {
    collapsedGroupsRef.current[groupName] = !collapsedGroupsRef.current[groupName];
    forceUpdate({}); // Force a re-render
  };

  // Run initialization only once
  useEffect(() => {
    if (initializationDoneRef.current) {
      return;
    }

    const newInitializations: Record<string, boolean> = {};
    let hasNewInitializations = false;

    filterGroups.forEach((group) => {
      // Skip initialization for optional filters
      if (!initializedRef.current[group.name] && group.showAll !== false && !group.optional) {
        newInitializations[group.name] = true;
        hasNewInitializations = true;
        initializedRef.current[group.name] = true;
        group.onSelectionChange(group.options.map((opt) => opt.value));
      }
    });

    if (hasNewInitializations) {
      setAllSelected((prev) => ({
        ...prev,
        ...newInitializations,
      }));
    }

    initializationDoneRef.current = true;
  }, [filterGroups]);

  const handleAllChange = (group: FilterGroup, isChecked: boolean) => {
    setAllSelected((prev) => ({
      ...prev,
      [group.name]: isChecked,
    }));

    // When unchecking "All", clear all selections
    // When checking "All", select all options
    group.onSelectionChange(isChecked ? group.options.map((opt) => opt.value) : []);
  };

  const handleIndividualChange = (group: FilterGroup, value: string, isChecked: boolean) => {
    if (group.showAll === false && group.options.length === 2) {
      // For single-select groups (like radio buttons), always set the clicked value
      group.onSelectionChange([value]);
      return;
    }

    // Multi-select behavior for all other groups
    const newValues = isChecked ? [...group.selectedValues, value] : group.selectedValues.filter((v) => v !== value);

    // Update allSelected state based on whether all options are selected
    setAllSelected((prev) => ({
      ...prev,
      [group.name]: newValues.length === group.options.length,
    }));

    group.onSelectionChange(newValues);
  };

  return (
    <Stack gap="md">
      <Select value={selectedOption} options={options} onChange={(value) => onOptionChange(value as T)} />
      <Divider />
      <Group justify="space-between">
        <Text weight="medium">Filters</Text>
        <Button
          variant="outline"
          size="xs"
          radius="xl"
          leftSection={<Icon name="restart_alt" size={16} color={inkLight} />}
          onClick={onResetFilters}
        >
          <Text size="small" color={inkLight}>
            Reset
          </Text>
        </Button>
      </Group>
      <Divider />

      {filterGroups.map((group) => (
        <Stack key={group.name} gap="xs">
          <Group justify="space-between">
            <Group gap="xs">
              {group.icon && <Icon name={group.icon} size={16} color={inkLight} />}
              <Text weight="medium" size="small">
                {group.name}
              </Text>
            </Group>
            <Icon
              name={collapsedGroupsRef.current[group.name] ? 'unfold_more' : 'unfold_less'}
              size={16}
              color={inkLight}
              style={{ cursor: 'pointer' }}
              onClick={() => toggleGroup(group.name)}
            />
          </Group>

          {!collapsedGroupsRef.current[group.name] && (
            <>
              {group.showAll !== false && (
                <Checkbox
                  label="All"
                  checked={allSelected[group.name]}
                  onChange={(event) => handleAllChange(group, event.currentTarget.checked)}
                />
              )}
              {group.options.map((option) => (
                <Checkbox
                  key={option.value}
                  label={option.label}
                  checked={group.selectedValues.includes(option.value)}
                  onChange={(event) => handleIndividualChange(group, option.value, event.currentTarget.checked)}
                />
              ))}
            </>
          )}
          {group !== filterGroups[filterGroups.length - 1] && <Divider style={{ marginTop: '8px' }} />}
        </Stack>
      ))}
    </Stack>
  );
}

const Checkbox = styled(MantineCheckbox)`
  .mantine-Checkbox-input:checked {
    background-color: var(--secondary-base);
    border-color: var(--secondary-base);
  }
  .mantine-Checkbox-input:indeterminate {
    background-color: var(--secondary-base);
    border-color: var(--secondary-base);
  }
  .mantine-Checkbox-input:disabled {
    background-color: var(--sky-light);
    border-color: var(--sky-light);
    opacity: 0.5;
  }
  .mantine-Checkbox-input:disabled:checked {
    background-color: var(--sky-dark);
    border-color: var(--sky-dark);
  }
  .mantine-Checkbox-label:has(+ .mantine-Checkbox-input:disabled) {
    color: var(--sky-dark);
  }
`;
